import React from "react";
import {Col, Row} from "react-bootstrap";
import {TemplateSelectProductRead} from "../../../../../shared_lsi_sg/TemplateSelectProductRead";
import ProductSummary from "../ProductSummary";
import {useSelector} from "react-redux";
import {selectIsPharmacy} from "../../../../../redux/customer/customerSlice";
import {ProductActionOrder} from "./ProductActionOrder";

export function ProductListOrder({order, showBtnAction = false, suspensionCallback}) {
    const isPharmacy = useSelector(selectIsPharmacy);

    return (
        <>
            {(order?.products || order?.productCart)?.map((ord, i) => (
                <div key={i} /*style={{ background: "#ecf0f49c" }}*/ className="w-100 content-product-item">
                    <Row className="mb-5">
                        <div className={"col-md-12 col-lg-12 col-xl "}>
                            <ProductSummary ord={ord} orderType={order?.type} orderDetail={order?.dettaglioOrdine}/>
                        </div>
                        {!!order?.actionsProduct?.length && (
                            <div className={"col-auto"}>
                                <ProductActionOrder actions={order?.actionsProduct} order={order}
                                                    suspensionCallback={suspensionCallback}/>
                            </div>
                        )}
                        {ord.detail && <Col xs="12" style={{marginTop: 20}}>
                            {/*{!isPharmacy && (<div className={"list-key-value mb-4"}>
                <span className={"flex-row align-items-baseline gap-2"}><span
                  className={"label-item"}>Prescrizione del </span><span>{order?.dataCreazioneOrdine ? dateformat_ggMMMyyyy(order?.dataCreazioneOrdine) : '-'}</span></span>
              </div>)}*/}

                            <TemplateSelectProductRead template={ord.template} data={ord.detail}/>
                        </Col>}
                    </Row>

                </div>
            ))}
        </>
    );
}
