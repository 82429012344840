export const modeVentiloName = {
  "cpap": "cpap",
  "autost": "autost",
  "apap": "apap",
  "st": "st",
  "t": "t",
  "apvc": "apvc",
  "s": "s"
};

export const modeSleepName = {
  "cpap": "cpap",
  "autocpap": "autocpap",
  "bilevels": "bilevels",
  "bilevelauto": "bilevelauto"
};

export const modeTherapyName = {
  "cpap": "cpap",
  "apap": "apap",
};


