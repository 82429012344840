import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import {isArray} from "../../shared/utils/arrayUtils";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import {modeChoose, modeChooseTherapy} from "../modeDataForm";
import {
    usePathologiesTherapyData
} from "../../app/pages/products/selectProduct/component/templates/data/usePathologiesTherapyData";


export function TemplateTherapyRead({data}: any) {
    const {t} = useLanguageForShared();
    const {pathologiesSNOMED} = usePathologiesTherapyData(t, data)

    const structureModeChoose = Object.keys(modeChooseTherapy).reduce((acc: any, mode) => {
        return {
            ...acc, [mode]: modeChoose[mode].map((ele: string) => (ele))
        };
    }, {});
    const checkPathologyType = () => {
        return isArray(data?.pathology) ? data?.pathology?.map((ele: string) => t(`pathology.${pathologiesSNOMED[ele]}`)).join(", ") : t(`pathology.${pathologiesSNOMED[data?.pathology]}`) || "-";
    };

    return (
        <div className={"template-sg ventilo-template row row-gap-4"}>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                <div className={"row row-gap-4"}>
                    {data?.pathology && data?.pathology.length > 0 && <div className="col-sm-12 col-md-12">
                        <LabelValue label={t("common.pathologies")}
                                    value={checkPathologyType()}/>
                        {data?.otherPathologies && <LabelValue
                            label={t("common.otherPathologies")}
                            value={data?.otherPathologies}/>}
                    </div>}
                    <div className="col-sm-12 col-md-12">
                        <LabelValue label={t("prescription.template.mode.label")}
                                    value={t(`prescription.template.${data?.mode}` || '-')}/>
                        {structureModeChoose[data?.mode]?.map((ele: any, k: number) => <LabelValue key={k}
                                                                                                   label={t(`prescription.template.${ele.name}.label`)}
                                                                                                   value={data[ele.name] || '-'}/>)}
                    </div>

                    <div className="col-sm-12 col-md-4">
                        <LabelValue
                            label={t("prescription.template.guaranteedVolume.label")}
                            value={data?.guaranteedVolume || '-'}/>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <LabelValue
                            label={t("prescription.template.modelDevice.label")}
                            value={data?.modelDevice || '-'}/>
                    </div>

                </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
                <div className={"row row-gap-4"}>
                    <div className="col-sm-12 col-md-12">
                        <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>

                        <div className="col-sm-12 col-md-12">
                            <LabelValue label={t("prescription.template.interface.label")}
                                        value={data?.interface ? t("common.yes") : t("common.no")} className={"me-5"}/>
                            {
                                data?.interface && <>
                                    <LabelValue
                                        label={t("prescription.template.interfaceType.label")}
                                        value={data?.interfaceType || "-"} className={"me-5"}/>
                                    <LabelValue
                                        label={t("prescription.template.interfaceModel.label")}
                                        value={data?.interfaceModel || "-"} className={"me-5"}/>
                                    <LabelValue
                                        label={t("prescription.template.interfaceSize.label")}
                                        value={data?.interfaceSize || "-"} className={"me-5"}/>
                                </>
                            }
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <LabelValue label={t("prescription.template.cannulaTracheostomy.label")}
                                        value={data?.cannulaTracheostomy ? t("common.yes") : t("common.no")}
                                        className={"me-5"}/>
                            {
                                data?.cannulaTracheostomy && <>
                                    <LabelValue
                                        label={t("prescription.template.cannulaType.label")}
                                        value={data?.cannulaType || "-"} className={"me-5"}/>
                                    <LabelValue
                                        label={t("prescription.template.cannulaModel.label")}
                                        value={data?.cannulaModel || "-"} className={"me-5"}/>
                                </>
                            }
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <LabelValue label={t("prescription.template.humidifier.label")}
                                        value={data?.humidifier ? t("common.yes") : t("common.no")} className={"me-5"}/>
                            {
                                data?.humidifier && <>
                                    <LabelValue
                                        label={t("prescription.template.notesHumidifier.label")}
                                        value={data?.notesHumidifier || "-"} className={"me-5"}/>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div className={"row row-gap-4"}>
                <div className="col-sm-12 col-md-12">
                    <LabelValue label={t("prescription.template.note.label")}
                                value={data?.note}/>

                </div>
            </div>
        </div>
    );
}
