import React, {useEffect} from "react";
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useModeTherapyData} from "./data/useModeTherapyData";
import {useTherapyData} from "./data/useTherapyData";
import {useTemplateFormOtherInfo} from "./data/useTemplateFormOtherInfo";
import {useEffectOnlyUpdate} from "../../../../../../shared/utils/use_custom/useEffectCustom";
import {copyObject} from "../../../../../../shared/utils/objUtils";
import {useParamsData} from "./data/useParamsData";
import {usePathologiesTherapyData} from "./data/usePathologiesTherapyData";

export function TemplateTherapy({dataForm, changeValue, errors, setData, isSubmit}) {
    const {t} = useLanguageForShared()
    const {formStructurePathologies} = usePathologiesTherapyData(t, dataForm)

    const {formStructureMode, formStructureModeChoose} = useModeTherapyData(t, dataForm);
    const {formStructureDevice} = useTherapyData(t, dataForm);
    const {
        formStructureDetailInterface,
        formStructureDetailHumidifier,
        formStructureInterface,
        formStructureHumidifier,
        formStructureNote
    } = useParamsData(t, dataForm)

    const {
        formStructureCannulaTracheostomy, formStructureDetailCannulaTracheostomy
    } = useTemplateFormOtherInfo(t, dataForm);

    useEffectOnlyUpdate(() => {
        if (!dataForm.interface) {
            const modeField = formStructureDetailInterface.map((ele) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.interface]);

    useEffectOnlyUpdate(() => {
        if (!dataForm.cannulaTracheostomy) {
            const modeField = formStructureDetailCannulaTracheostomy.map((ele) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.cannulaTracheostomy]);

    useEffectOnlyUpdate(() => {
        if (!dataForm.humidifier) {
            const modeField = formStructureDetailHumidifier.map((ele) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.humidifier]);

    const changePathologyData = (data, val) => {
        if (dataForm?.pathology?.includes("Other") && data.name === 'pathology' && !data.value.includes("Other")) {
            changeValue({
                value: {
                    pathology: data.value,
                    otherPathologies: ''
                }
            })
        } else {
            changeValue(data)
        }
    }
    useEffect(() => {
        if (dataForm.mode) {
            const copyDataForm = copyObject(dataForm)
            delete copyDataForm?.pressure
            delete copyDataForm?.press_min
            delete copyDataForm?.press_max
            setData(copyDataForm)
        }
    }, [dataForm?.mode]);

    console.log('isSubmit', isSubmit)
    return (
        <>
            <FormElements
                isSubmit={isSubmit}
                structure={formStructurePathologies}
                dataForm={dataForm}
                errors={errors}
                changeValue={changePathologyData}
            />
            <div className={'d-flex'}>
                <div className={'w-100'}>
                    <FormElements
                        isSubmit={isSubmit}
                        structure={formStructureMode}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}
                    />
                </div>
                <div className={'w-100'}>
                    <FormElements
                        isSubmit={isSubmit}
                        structure={formStructureDevice}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}/>
                </div>
            </div>
            <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
            <FormElements
                structure={formStructureInterface}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
                isSubmit={isSubmit}
            />
            <FormElements
                isSubmit={isSubmit}
                structure={formStructureCannulaTracheostomy}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
            />
            <FormElements
                structure={formStructureHumidifier}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
                isSubmit={isSubmit}
            />
            <FormElements
                structure={formStructureNote}
                dataForm={dataForm}
                changeValue={changeValue}
                errors={errors}
                isSubmit={isSubmit}
            />
        </>
    );
}
