import React from "react";
import { templateNameId, templateNameType } from "./templateData";
import { TemplateNoteRead } from "./templateRead/TemplateNoteRead";
import { TemplateOxigenRead } from "./templateRead/TemplateOxigenRead";
import { TemplateVentilotherapyRead } from "./templateRead/TemplateVentilotherapyRead";
import { TemplateSleepRead } from "./templateRead/TemplateSleepRead";
import { TemplateConcentratorRead } from "./templateRead/TemplateConcentratorRead";
import { TemplateSleepStudyRead } from "./templateRead/TemplateSleepStudyRead";
import { TemplateScoredDiagnosticStudyRead } from "./templateRead/TemplateScoredDiagnosticStudyRead";
import { TemplateTherapyRead } from "./templateRead/TemplateTherapyRead";

export function TemplateSelectProductRead({ template, data }) {
  const templateList = {
    [templateNameId[templateNameType.note]]: <TemplateNoteRead data={data} />,
    [templateNameId[templateNameType.ossigeno]]: <TemplateOxigenRead data={data} />,
    [templateNameId[templateNameType.ventiloterapia]]: <TemplateVentilotherapyRead data={data} />,
    [templateNameId[templateNameType.sleep]]: <TemplateSleepRead data={data} />,
    [templateNameId[templateNameType.concentrator]]: <TemplateConcentratorRead data={data} />,
    [templateNameId[templateNameType.sleepStudy]]: <TemplateSleepStudyRead data={data} />,
    [templateNameId[templateNameType.therapy]]: <TemplateTherapyRead data={data} />,
    [templateNameId[templateNameType.scoredDiagnosticStudy]]: <TemplateScoredDiagnosticStudyRead data={data} />,
  };
  return (
    <>
      {templateList[template]}
    </>
  );
}
