import {cfRegex} from "./../../../../utils/cfRegex";
import * as Yup from "yup";
import {getTodayDate, toEnDateString} from "../../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());

export const phone = Yup.object().shape({
        phone: Yup.number()
            .typeError("Inserire un numero valido")
            .when("mobile", {
                is: (mobile) => !mobile || mobile.length === 0,
                then: Yup.number().typeError("Inserire un numero valido").required("At least one of the fields is required"),
            }).nullable(),
        mobile: Yup.number()
            .typeError("Inserire un numero valido")
            .when("phone", {
                is: (mobile) => !mobile || mobile.length === 0,
                then: Yup.number().typeError("Inserire un numero valido").required("At least one of the fields is required"),
            }).nullable(),
    },
    ["phone", "mobile"]
);

//
const patientFiscalCodeValidation = () => {
    if (!process.env.REACT_APP_VALIDATIONS_PATIENT_CF || process.env.REACT_APP_VALIDATIONS_PATIENT_CF === "true") {
        return Yup.string()
            .matches(cfRegex, "Inserire CF valido")
            .required("Obbligatorio").nullable()
    }
    return Yup.string().required("Obbligatorio").nullable()
}

export const validationsPatient = Yup.object().shape({
        name: Yup.string().required("Obbligatorio").nullable(),
        surname: Yup.string().required("Obbligatorio").nullable(),
        cf: patientFiscalCodeValidation(),
        birthDate: Yup.date()
            .max(todayDate, `La data non può essere maggiore di oggi`)
            .required("Obbligatorio").nullable(),
        email: Yup.string().email("Inserire mail valida").nullable(),
        phone: Yup.number()
            .typeError("Inserire un numero valido")
            .when("mobile", {
                is: (mobile) => !mobile,
                then: Yup.number().typeError("Inserire un numero valido").required("Compilare almeno uno tra telefono o cellulare"),
            }).nullable(),
        mobile: Yup.number()
            .typeError("Inserire un numero valido")
            .when("phone", {
                is: (phone) => !phone,
                then: Yup.number().typeError("Inserire un numero valido").required("Compilare almeno uno tra telefono o cellulare"),
            }).nullable(),
        residence: Yup.object().shape({
            number: Yup.mixed().nullable(),
            internal: Yup.number().typeError("Inserire un numero valido").nullable(),
            cap: Yup.number().typeError("Inserire un numero valido").nullable(),
        }),
        domicile: Yup.object().shape({
            number: Yup.mixed().nullable(),
            internal: Yup.number().typeError("Inserire un numero valido").nullable(),
            cap: Yup.number().typeError("Inserire un numero valido").nullable(),
        }).nullable(),
    },
    ["phone", "mobile"]
);
