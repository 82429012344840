import {useFormik} from "formik";
import React, {useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../../shared/ui/icon/Icon";
import {
    formStructureEditPatientRegistryRow,
    formStructureEditPatientRegistryRowDomicile,
    formStructureEditPatientRegistryRowResidence
} from "./patientRegistryData";

import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import {
    createPatient,
    updatePatient
} from "../../../../redux/order/orderCrud";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedCustomer} from "../../../../redux/customer/customerSlice";
import * as routeName from "../../../routes/routeNameAuthType";
import {GetPatientAction} from "../../../../redux/patient/patientActions";
import {useRoute} from "../../../utils/useRoute";
import {setErrorTypeAction} from "../../../../redux/errors/errorsActions";
import {validationsPatient} from "./data/validations";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const PatientRegistry = ({patient = {}, closeModalHandler}) => {
    const {t} = useLanguageForShared();

    const customer = useSelector(selectSelectedCustomer);
    const dispatch = useDispatch();
    const {changeRoute} = useRoute();
    const formik = useFormik({
        initialValues: patient,
        validationSchema: validationsPatient,
        onSubmit: (values) => {
            console.log('edit?', patient)
            if (patient?.id) {
                updatePatient(values)
                    .then((res) => {
                        //const callback = () => changeRoute(routeName.selectedPatientPage);
                        dispatch(GetPatientAction(t, res.id, /*callback*/));
                    })
                    .catch((err) => {
                        const message = err?.response?.data?.Message;
                        if (message === "ERR_CF_DUPLICATE") {
                            dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
                        } else if (message === "ERR_CF_INCOHERENT") {
                            dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
                        } else if (message === "ERR_EMAIL_DUPLICATE") {
                            dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
                        } else if (message === "ERR_EMAIL_INCOHERENT") {
                            dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
                        } else {
                            dispatch(
                                dispatch(setErrorTypeAction(t('sg.msg.errorUpdatePatient')))
                            );
                        }
                    });
            } else {
                createPatient(values, customer.id)
                    .then((res) => {
                        const callback = () => changeRoute(routeName.products);
                        dispatch(GetPatientAction(t, res.id, callback));
                    })
                    .catch((err) => {
                        const message = err?.response?.data?.Message;
                        if (message === "ERR_CF_DUPLICATE") {
                            dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
                        } else if (message === "ERR_CF_INCOHERENT") {
                            dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
                        } else if (message === "ERR_EMAIL_DUPLICATE") {
                            dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
                        } else if (message === "ERR_EMAIL_INCOHERENT") {
                            dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
                        } else {
                            dispatch(
                                setErrorTypeAction(t('sg.msg.errorInsertPatient'))
                            );
                        }
                    });
            }
            closeModalHandler();
        }
    });

    console.log('formik.values', formik.values)
    return (
        <div>
            <div className="d-flex">
                <p
                    className="sub-title mb-4">{patient?.id ? t('sg.editPatientRegistry') : t('sg.addPatientRegistry')}</p>
            </div>
            <Form onSubmit={formik.handleSubmit}>
                <FormElements
                    structure={formStructureEditPatientRegistryRow(t)}
                    formik={formik}
                ></FormElements>
                <FormElements
                    structure={formStructureEditPatientRegistryRowResidence(t)}
                    formik={formik}
                ></FormElements>
                <FormElements
                    structure={formStructureEditPatientRegistryRowDomicile(t)}
                    formik={formik}
                ></FormElements>
                <Row className="justify-content-end mt-5">
                    <Col xs="auto">
                        <BtnSecondary
                            onClick={() => closeModalHandler()
                                /*changeRoute(routeName.selectedPatientPage)*/}>
                            {t('action.cancel')}
                        </BtnSecondary>
                    </Col>
                    <Col xs="auto">
                        <BtnPrimary
                            type={"submit"}
                            data-test={
                                patient?.id ? "edit-patient-test" : "create-patient-test"
                            }
                        >
                            {patient?.id ? t('action.edit')
                                : t('sg.form.action.addNewService')}
                        </BtnPrimary>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default PatientRegistry;
