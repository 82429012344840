export const usePathologiesTherapyData = (t: any, dataForm: any) => {

  const pathologiesSNOMED = {
    "13645005": "BPCO",
    "78275009": "OSAS",
    "195967001": "Asthma",
    "50043002": "Other",
    "73430006": "sleepApnea"
  };


  const formStructurePathologies = [
    {
      name: "pathology",
      type: "select",
      label: `${t("common.pathologies")}*`,
      options: Object.keys(pathologiesSNOMED).map(ele => ({
          label: t(`pathology.${pathologiesSNOMED[ele]}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text",
        multi: true
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" }
    },
  ];


  return {
    pathologiesSNOMED,
    formStructurePathologies
  };
};
