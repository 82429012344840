export const testSNOMED = {
  "60554003": "polysomnography",
  "29303009": "ecg",
  "127783003": "spirometry",
  "252465000": "pulseOximetry",
  "264598005": "oximetry"
};


export const suspectedPathology = {
  "copd": "copd",
  "sleepApnea": "BPCO",
};