import { required } from "../../../../../../../shared/utils/forms/utils/validationType";

export const useValidationScoredDiagnosticStudy = (t: any, dataForm: any) => {
  const validations = [
    {
      name: "test",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.test.label")
          }
        }
      ]
    },
    {
      name: "suspectedPathologies",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.suspectedPathologies.label")
          }
        }
      ]
    },
    {
      name: "testExecutionAddress",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.testExecutionAddress.label")
          }
        }
      ]
    },
    {
      name: "clinicalCenter",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.clinicalCenter.label")
          }
        }
      ]
    },
    {
      name: "request",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.request.label")
          }
        }
      ]
    },

  ];

  return validations;

};
